export function checkPhone(phone) {
    return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(phone);
}

export function checkId(id) {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(id);
}

export function SetStorage(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
}

export function GetStorage(key) {
    return JSON.parse(window.localStorage.getItem(key));
}
