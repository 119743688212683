import Vue from "vue";
import VueRouter from "vue-router";

import Page1 from '../views/page1.vue'
import Page2 from '../views/page2.vue'
import Page3 from '../views/page3.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "page1",
    component: Page1,
  },
  {
    // 日程
    path: "/schedule",
    name: "schedule",
    component: Page2,
  },
  {
    // 报名
    path: "/signup",
    name: "signup",
    component: Page3,
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
