import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as utils from './utils';
import api from './service/http'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.config.productionTip = false;
Vue.prototype.$u = utils;
Vue.prototype.$api = api

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
