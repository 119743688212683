<template>
  <div class="page2">
    <img src="../assets/images/page2.png" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Page2',
};
</script>

<style scoped>
.page2 {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: #020202;
}
.page2 img {
  width: 100%;
}
</style>
