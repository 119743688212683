<template>
  <div class="page1">
    <div class="page1_logo">
      <div class="line1"></div>
      <img class="logo" src="../assets/images/page1-logo.png" />
      <div class="line2"></div>
    </div>
    <div class="page1_desc" @click="toSchedule">
      <span class="zh_title">共擎 以赴</span>
      <span class="en_title">All In One, Win For You</span>
      <span class="zh_title">2024 大众汽车中国零售精英大赛</span>
      <span class="en_title">2024 Volkswagen China Retail Championship</span>
    </div>
    <img class="page1_car" src="../assets/images/page1.png" />
    <div class="page1_submit" @click="toSignup">立即报名</div>
  </div>
</template>

<script>
export default {
  name: 'Page1',
  methods: {
    // 去报名页面
    toSignup() {
      this.$router.push('/signup');
    },
    // 去日程页面
    toSchedule() {
      this.$router.push('/schedule');
    },
  },
};
</script>

<style scoped>
.page1 {
  height: 100vh;
  background: #000;
}
.page1_logo {
  display: flex;
  align-items: center;
  padding: 30px 0 0;
}
.line1,
.line2 {
  height: 2px;
  background: #fff;
  opacity: 0.6;
}
.line1 {
  flex: 0 0 22px;
}
.line2 {
  flex: 1;
}
.logo {
  width: 36px;
  height: 36px;
  margin: 0 8px;
}
.page1_car {
  width: 100%;
  margin-top: 50px;
  pointer-events: none;
}
.page1_desc {
  padding: 20px 0 0 30px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #ffffff;
  line-height: 1.5;
}
.zh_title {
  font-family: HYQiHei, HYQiHei;
}
.en_title {
  font-family: VWHead, VWHead;
  opacity: 0.6;
  margin-bottom: 8px;
}
.page1_submit {
  width: 140px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 3px;
  position: fixed;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
}
</style>
