import axios from 'axios'


const ajax = axios.create({
  baseURL: ' https://xh.bjlitian.com/api',
  // baseURL: 'http://localhost:7001',
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  }
})

ajax.interceptors.response.use(res => {
  return res.data
})

export default ajax